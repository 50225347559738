<template>
    <div class="waterfall">
        <vue-waterfall-easy ref="waterfall" :imgsArr="imgsArr" @scrollReachBottom="getData()">
            <div v-show="imgsArr[props.index].title" class="info" slot-scope="props">{{ imgsArr[props.index].title}}</div>
        </vue-waterfall-easy>
    </div>
</template>

<script>
// import myCardShare from "../components/myCardShare";
import vueWaterfallEasy from "vue-waterfall-easy";
import {shareContent_1} from "../assets/htmlContent/shareContent/shareContent_1";
export default {
    data() {
        return {
            imgsArr: [],
            page: 0,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            var list = shareContent_1;
            this.page += 1;
            if (this.page == 2) {
                this.$refs.waterfall.waterfallOver();
            } else {
                this.imgsArr = this.imgsArr.concat(list);
            }
        },
    },
    components: {
        // myCardShare,
        vueWaterfallEasy,
    },
};
</script>

<style  scoped>
.waterfall {
    height: 100%;
}
.info {
    text-align: left;
    padding: 17px 10px;
    font-size: 18px;
    color: rgba(51, 51, 51, 1);
}
.waterfall >>> a {
    text-decoration: none;
}
.waterfall
    >>> .vue-waterfall-easy-scroll
    .vue-waterfall-easy
    .img-box
    .img-inner-box {
    border-radius: 20px;
    overflow: hidden;
}
</style>