export { json as shareContent_1 };
const json = [
  {
    title: "",
    src: require("/src/assets/images/card/share-01.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-02.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-03.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-04.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-05.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-06.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-07.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-08.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-09.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-10.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-11.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-12.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-13.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-14.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-15.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-16.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-17.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-18.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-19.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-20.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-21.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-22.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-23.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-24.jpg"),
  },
  {
    title: "",
    src: require("/src/assets/images/card/share-25.jpg"),
  },
];
